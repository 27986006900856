<template>

  <b-modal id="question-log-modal" ref="question-log-modal" size="lg" centered @show="fetchQuestionLogs"
    modal-class="custom-modal-for-logs" @hidden="handleClose">
    <template #modal-title>
      <div class="d-flex flex-row align-items-center">
        <span>Question Logs</span>
      </div>
    </template>
    <hr v-if="!finalLogsData.length > 0 || isLoading" class="my-0">
    <div style="min-height: 250px; max-height: 450px; overflow-y: auto; padding-right: 10px;">
      <div class="modal_content ">
        <b-overlay rounded="sm">
          <template v-if="!isLoading">
            <template v-if="finalLogsData.length > 0">

              <b-table :items="finalLogsData" :fields="fields" responsive class="mb-0" id="custom-table">

                <template #cell(user)="data">
                  <span>
                    {{ data.item.user || "-" }} <br />
                    {{ data.item.user_name || "-" }}
                  </span>
                </template>

                <template #cell(date)="data">
                  <span>
                    {{ data.item.date || "-" }} <br />
                    {{ data.item.time || "-" }}
                  </span>
                </template>

                <template #cell(questionTitle)="data">
                  <div class="d-flex align-items-center" >
                    {{ data.item.questionTitle || "-" }}
                  </div>
                </template>

                <template #cell(action)="data">
                  <div class="d-flex align-items-center">
                    {{ data.item.action || "-" }}
                  </div>
                </template>

                <template #cell(changes)="data">
                  <div class="d-flex align-items-center" >
                    {{ data.item.changes || "-" }}
                  </div>
                </template>
              </b-table>

            </template>
            <div v-else style="height: 250px; display: flex; justify-content: center; align-items: center;">
              <h4 class="text-center m-2 text">No records found</h4>
            </div>
          </template>
          <div v-else class="p-4 text-center"
            style="height: 250px; display: flex; justify-content: center; align-items: center;">
            <b-spinner v-if="isLoading" label="Loading..." medium></b-spinner>
          </div>
        </b-overlay>

      </div>

    </div>
    <hr v-if="!finalLogsData.length > 0 || isLoading" class="my-0">

    <template #modal-footer>
      <b-button variant="secondary" @click="closeModal">Close</b-button>
    </template>
  </b-modal>

</template>


<script>
import axios from 'axios';
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BOverlay,
  BFormGroup,
  BTableSimple,
  BThead,
  BFormSelect,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BTooltip,
    BOverlay,
    BFormGroup,
    BTableSimple,
    BThead,
    BFormSelect,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BSpinner,
    ToastificationContent
  },

  props: {
    question: {
      type: String,
      required: true,
      default: "",
    },

  },
  data() {
    return {
      feedbackText: "",
      isLoading: true,
      hideCheckBox: false,
      totalItems: 0,
      logsData: [],
      finalLogsData: [],
      currentPage: 1,
      questionId: this.question,
      fields: [
        { key: "user", label: "USER" },
        { key: "date", label: "DATE" },
        { key: "questionTitle", label: "QUESTIN TITLE" },
        { key: "action", label: "ACTION" },
        { key: "changes", label: "CHANGES" },
      ],
      questionLogs: {
        data: [
          {
            user: 'Rizwan Iqbal',
            email: 'rizwan.iqbal@gmail.com',
            date: '21/02/2025',
            time: '12:00 PM',
            questionTitle: 'This is a question title',
            action: 'Created',
            changes: 'Here is the detail of changes'
          }
        ]
      },

    };
  },


  watch: {

  },

  methods: {

    closeModal() {
      this.$refs['question-log-modal'].hide();
      this.logsData = [];
      this.finalLogsData = [];
    },
    handleClose() {
      console.log("Modal closed!");
      this.logsData = [];
      this.finalLogsData = [];
    },

    async fetchQuestionLogs() {
      this.isLoading = true;
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/question-logs/single-question-logs`,
          {
            params: {
              questionId: this.question,
              // currentPage: this.currentPage,
              // perPage: 10,
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        );

        this.logsData = response.data.logs || [];

        if (this.logsData.length > 0) {
          this.finalLogsData = this.processLogsData(this.logsData);
        } else {
          this.finalLogsData = [];
        }

        this.notificationMessage = response.data.message || "Question logs retrieved successfully.";
        this.showToasted("success");

      } catch (error) {
        this.notificationMessage = "Error fetching question logs.";
        this.showToasted("danger");
      } finally {
        this.isLoading = false;
      }
    },


    showToasted(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.notificationMessage,
          variant,
        },
      });
    },

    processLogsData(logsData) {
      try {
        return logsData.map((log) => {

          const oldData = log.old_data ? JSON.parse(log.old_data) : null;
          const newData = log.new_data ? JSON.parse(log.new_data) : null;

          return {
            user: log.user_email || "-",
            user_name: log.user_name || "-",
            date: log.created_at ? this.formatDate(log.created_at) : "-",
            time: log.created_at ? this.formatTime(log.created_at) : "-",
            questionTitle: (oldData?.title || newData?.title) || "-",
            action: log.action || "-",
            changes: log.description || "-",
          };
        });
      } catch (error) {
        console.error("Error processing logs data:", error);
        return [];
      }
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    },

    formatTime(dateString) {
      const date = new Date(dateString);

      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    },

  },
};

</script>